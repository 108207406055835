import { FC, memo, useEffect, useImperativeHandle, useRef } from 'react'
import Link from 'next/link'
import classNames from 'classnames'
import gsap from 'gsap'

import css from './PageNotFound.module.scss'

import { PageHandle, PageProps } from '@/data/types'

import { getImageUrl, updateCustomProperties } from '@/utils/basic-functions'
import { MOTION } from '@/utils/motion-values'
import sass from '@/utils/sass'

import useLayout from '@/hooks/use-layout'
import { useRefs } from '@/hooks/use-refs'

import BaseImage from '@/components/BaseImage/BaseImage'
import Heading from '@/components/Heading/Heading'
import PillButton from '@/components/PillButton/PillButton'
export interface PageNotFoundProps extends PageProps {
  className?: string
}

export type ViewRefs = {
  root: HTMLDivElement
  header: HTMLHeadingElement
  line: HTMLSpanElement
  extra1: HTMLDivElement
  extra2: HTMLDivElement
  image: HTMLImageElement
  button: HTMLDivElement
}

const View: FC<PageNotFoundProps> = ({ className, onReady }) => {
  const handleRef = useRef<PageHandle>(null)
  const viewRefs = useRefs<ViewRefs>()
  const layout = useLayout()

  useEffect(() => {
    gsap.set(viewRefs.root.current, { opacity: 0 })

    const readyTimeout = setTimeout(() => {
      onReady?.(handleRef)
    }, MOTION.general.delayForLayout)

    return () => {
      if (readyTimeout) clearTimeout(readyTimeout)
    }
  }, [onReady, viewRefs])

  useImperativeHandle(handleRef, () => ({
    animateIn: () => {
      const wipeImage = viewRefs.image.current!.querySelector('img') as HTMLImageElement

      return gsap
        .timeline()
        .set(viewRefs.root.current, { opacity: 1 }, 0)
        .textRiseByCharsIn(
          viewRefs.header.current!,
          {
            duration: 1.5,
            charDuration: 1.5,
            charOffset: 0.075,
            immediateRender: true,
            ease: 'ease-split-text'
          },
          '<+0.1'
        )
        .fromTo(
          viewRefs.line.current!,
          { scaleX: 0, transformOrigin: 'left center' },
          { scaleX: 1, duration: 1.2, immediateRender: true, ease: 'ease-scale' },
          '<+0.3'
        )
        .fromTo(
          [viewRefs.extra1.current!, viewRefs.extra2.current!],
          { opacity: 0 },
          {
            opacity: 1,
            duration: 1.2,
            stagger: 0.15,
            ease: 'ease-opacity'
          },
          '<+0.1'
        )
        .maskWipeIn(
          viewRefs.image.current!,
          {
            direction: 'up',
            duration: 1.5,
            immediateRender: true,
            ease: 'ease-wipe'
          },
          '<+0.3'
        )
        .fromTo(wipeImage, { yPercent: 4 }, { yPercent: 0, duration: 1.4, ease: 'ease-translate' }, '<')
        .fromTo(
          viewRefs.button.current!,
          { opacity: 0 },
          {
            opacity: 1,
            duration: 0.8,
            ease: 'ease-opacity'
          },
          '<+0.3'
        )
    },
    animateOut: () => {
      return gsap.timeline().to(viewRefs.root.current, { autoAlpha: 0, ease: 'ease-opacity', duration: 0.8 }, 0)
    }
  }))

  useEffect(() => {
    updateCustomProperties({
      backButtonVisibility: 'hidden',
      backButtonColor: sass.transparent,
      backButtonOpacity: '0',
      navButtonBackground: sass.black,
      navButtonColor: sass.white,
      navButtonBorder: 'transparent',
      backButtonTransitionDuration: '0.4s'
    })
  }, [])

  return (
    <main className={classNames('PageNotFound', css.root, className)} ref={viewRefs.root}>
      <div className={css.header}>
        <h1 className={css.title} ref={viewRefs.header}>
          404
        </h1>
        <div className={css.subtitle}>
          <div className={css.paraTopWrapper}>
            <span className={css.line} ref={viewRefs.line} />
            <Heading as="h4" italic className={css.paraTop} ref={viewRefs.extra1}>
              Oops!
            </Heading>
          </div>

          <Heading as="h4" italic={layout.mobile} ref={viewRefs.extra2}>
            Page not found
          </Heading>
        </div>
      </div>

      <BaseImage
        className={css.image}
        data={getImageUrl('not-found/404-photo.jpg')}
        fill={false}
        ref={viewRefs.image}
      />

      <Link href="/">
        <PillButton
          className={css.button}
          flexAlignment="center"
          theme="border"
          size={layout.desktop ? 'medium' : 'small'}
          ref={viewRefs.button}
        >
          Go Back Home
        </PillButton>
      </Link>
    </main>
  )
}

View.displayName = 'PageNotFound-View'

// Controller (handles global state, router, data fetching, etc. Feeds props to the view component)
const PageNotFound: FC<PageNotFoundProps> = (props) => {
  return <View {...props} />
}

PageNotFound.displayName = 'PageNotFound'

export default memo(PageNotFound)
